import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { WrapRootElement } from './styles'

import { device } from '../device'
import { spacers, palette } from '../styles/theme'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { PostShape } from '../types'

const PostArticle = styled.article`
  margin: 0;
  padding: 0;
  max-width: 1204px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const PostHeader = styled.header`
  margin: 0;
  margin-top: ${spacers.spacer6};
  margin-bottom: ${spacers.spacer5};
  padding: 0;
`

const PostTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-family: 'Jorge-Luis-Cafe-Bold';
  font-size: 1.875rem;
  line-height: 1.5;
  color: ${palette.color6};
  @media ${device.intermediate} {
    font-size: 2.25rem;
  }
  @media ${device.web} {
  }
`

const PrivacyPolicyTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout isBlog={false} location={location} title={siteTitle}>
      <SEO title="Privacy Policy" description="Privacy Policy" />
      <PostArticle>
        <PostHeader>
          <PostTitle>Privacy Policy</PostTitle>
        </PostHeader>
        <WrapRootElement>
          <div id="innerHtml" dangerouslySetInnerHTML={{ __html: post.html }} />
        </WrapRootElement>
      </PostArticle>
    </Layout>
  )
}

PrivacyPolicyTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PostShape,
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}

export default PrivacyPolicyTemplate

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: "/privacy-policy/" } }) {
      id
      html
    }
  }
`
