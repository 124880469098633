import PropTypes from 'prop-types'

export const PostShape = PropTypes.shape({
  html: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    readingTime: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
  }),
  frontmatter: {
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.object,
    addMarginTop: PropTypes.bool,
    containImage: PropTypes.bool,
  },
})
